:global(#root) {
  height: 100%;
}

:global(.App-logo) {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

:global(.App-header) {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

:global(.App-intro) {
  font-size: large;
}

@keyframes :global(App-logo-spin) {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

:global(.wideContainer) {
  width: 95% !important;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance:textfield;
}
