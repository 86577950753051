
.noscrollbar::-webkit-scrollbar {
  width: 0px;
}

/* width */
.scrollbar::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

/* Track */
.scrollbar::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
.scrollbar::-webkit-scrollbar-thumb {
  background: #F2F2F2;
  border-radius: 4px;
}
